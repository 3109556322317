<template>
  <v-btn v-bind="$attrs" class="text-capitalize" :href="href"
    ><slot name="button">Let Peeps Know</slot>
    <v-icon v-if="icon"> {{ icon }} </v-icon></v-btn
  >
</template>

<script>
export default {
  props: {
    subject: {
      type: String,
      required: true
    },
    body: {
      type: String,
      required: true
    },
    icon: {
      type: String
    }
  },
  computed: {
    href() {
      let res = ''
      res += `mailto:trurowaterprotectors@gmail.com`
      res += `?subject=Let me know when ${this.subject}`
      res += `&body=${this.body}`

      return res
    }
  }
}
</script>

<style></style>
